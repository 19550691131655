
















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

// Components
import CardWrapper from '@/components/_uikit/CardWrapper.vue'
import Tag from '@/components/_uikit/Tag.vue'
// store
import AuthModule from '@/store/modules/auth'
// utils
import { formatDate } from '@/utils/functions'

interface IDoc {
  title: string,
  date: string,
  type: string,
  href: string,
}

@Component({
  components: {
    CardWrapper,
    Tag,
  },
})
export default class DocsCard extends Vue {
  @Prop({ default: null })
  readonly document!: IDoc

  @Prop({ default: 0 })
  readonly order!: number

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private formattedDate(date: string): ReturnType<typeof formatDate> {
    return formatDate(date, 'dd MMM yyyy', this.isLocalTimezone)
  }

  private get isAuth() {
    return AuthModule.self
  }
}
