









import Vue from 'vue'
import Component from 'vue-class-component'

import { DocsEnum } from '@/store/types/docs'
// Components
import DocsCard from '@/components/cards/DocsCard.vue'

@Component({
  components: {
    DocsCard,
  },
})
export default class DocsList extends Vue {
  private get docs() {
    return [
      { date: '2024-06-24', href: DocsEnum.PERSONAL, title: 'Согласие на обработку персональных данных', type: 'personal' },
      { date: '2024-06-24', href: DocsEnum.POLICY, title: 'Политика конфиденциальности персональных данных', type: 'policy' },
      { date: '2024-06-24', href: DocsEnum.USER_TERMS, title: 'Пользовательское соглашение', type: 'terms' },
      { date: '2024-06-24', href: DocsEnum.MENTOR_TERMS, title: 'Условия использования платформы для преподавателей', type: 'terms' },
      { date: '2024-06-24', href: DocsEnum.OFFER_STRELKOVA, title: 'Договор оказания услуг (договор-оферта) ИП Стрелкова Э. Д.', type: 'offerHistory' },
      { date: '2024-06-24', href: DocsEnum.OFFER_ROGOVENKO, title: 'Договор оказания услуг (договор-оферта) ИП Роговенко П. А.', type: 'offerSocial' },
      { date: '2024-06-24', href: DocsEnum.OFFER_SHARAFIEVA, title: 'Договор оказания услуг (договор-оферта) ИП Шарафиева Н. Р.', type: 'offerLiterature' },
      { date: '2024-06-24', href: DocsEnum.OFFER_EYTUTIS, title: 'Договор оказания услуг (договор-оферта) ИП Эйтутис В. В.', type: 'offerLiterature' },
      { date: '2024-06-24', href: DocsEnum.OFFER_MALYSHEV, title: 'Договор оказания услуг (договор-оферта) ИП Малышев М. А.', type: 'offerBiology' },
      { date: '2024-16-24', href: DocsEnum.OFFER_SERGEEVA, title: 'Договор оказания услуг (договор-оферта) ИП Сергеева Р. Р.', type: 'offerBiology' },
      { date: '2024-16-24', href: DocsEnum.OFFER_VOLVAKA, title: 'Договор оказания услуг (договор-оферта) ИП Вольвака А. А.', type: 'offerBiology' },
      { date: '2024-06-24', href: DocsEnum.OFFER_BABICH, title: 'Договор оказания услуг (договор-оферта) ИП Бабич К. Д.', type: 'offerBiology' },
      { date: '2024-06-24', href: DocsEnum.OFFER_SHVETSOV, title: 'Договор оказания услуг (договор-оферта) ИП Швецов А. А.', type: 'offerBiology' },
      { date: '2024-11-22', href: DocsEnum.OFFER_ANTONOV, title: 'Договор оказания услуг (договор-оферта) ИП Антонов Д. И.', type: 'offerBiology' },
    ]
  }
}
